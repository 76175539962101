<template>

	<!-- Information Card 2 -->
	<a-card :bordered="false" class="card-info-2 h-full">
		<div class="col-content h-full" style="background-image: url('images/info-card-2.jpg')">
			<div class="card-content">
				<h5>Hey John!</h5>
				<p>Wealth creation is an evolutionarily recent positive-sum game. It is all about who take the opportunity first.</p>
			</div>
			<div class="card-footer">
				<a-button ghost  shape="round">
					<span>Read More</span>
    				<a-icon class="ml-5" type="arrow-right" />
				</a-button>
			</div>
		</div>
	</a-card>
	<!-- / Information Card 2 -->

</template>

<script>

	export default ({
		data() {
			return {
			}
		},
	})

</script>